.sigCanvas {
  background-color: rgba(221, 170, 68, 0.50);
  border: 1px solid rgba(221, 170, 68, 0.75);
  width: 100%;
}

.upload-icon {
  width: 50px;
  height: 50px;
  background: url(../images/button_builder/select.png) no-repeat center center; 
  background-size: 100%;
  text-align: center;
  margin: 0 auto;
  padding-top: 30px;
}

.fc-daygrid-event {
  white-space:normal;
}