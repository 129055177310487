@import "theme/general";
@import "theme/generic";
@import "theme/card";
@import "theme/loader";
@import "theme/header";
@import "theme/sidebar";
@import "theme/buttons";
@import "theme/color";
@import "theme/grid";
@import "theme/errorpage";
@import "theme/icons";
@import "theme/email-application";
@import "theme/chat";
@import "theme/alert";
@import "theme/popover";
@import "theme/list";
@import "theme/switch";
@import "theme/megaoption";
@import "theme/user-profile";
@import "theme/progress";
@import "theme/according";
@import "theme/login";
@import "theme/forms";
@import "theme/comingsoon";
@import "theme/radio";
@import "theme/form-input";
@import "theme/datatable";
@import "theme/page";
@import "theme/ecommerce";
@import "theme/pricing";
@import "theme/navs";
@import "theme/dropdown";
@import "theme/landing";
@import "theme/table";
@import "theme/chart";
@import "theme/timeline-v";
@import "theme/tab";
@import "theme/gallery";
@import "theme/blog";
@import "theme/footer";
@import "theme/form-wizard";
@import "theme/badge";
@import "theme/search";
@import "theme/datepicker";
@import "theme/tour";
@import "theme/custom-theme-options";
@import "theme/ribbon";
@import "theme/breadcrumb";
@import "theme/steps";
@import "theme/theme-customizer";
@import "theme/faq";
@import "theme/knowledgebase";
@import "theme/document";
@import "theme/job-search";
@import "theme/learning";
@import "theme/order-history";
@import "theme/internationalization";
@import "theme/social-app";
@import "theme/avatars";
@import "theme/bookmark";
@import "theme/cart";
@import "theme/wishlist";
@import "theme/todo";
@import "theme/checkout";
@import "theme/typeahead-search";
@import "theme/calendar";
@import "theme/rating";
@import "theme/dashboard_default";
@import "theme/dashboard_ecommerce";
@import "theme/dashboard_university";
@import "theme/dashboard_bitcoin";
@import "theme/dashboard_server";
@import "theme/dashboard_project";
@import "theme/react-plugin";
@import "theme/box-layout";
@import "theme/rtl";
@import "theme/responsive";
@import "my-workplanner";

.PrivateTabIndicator-colorPrimary-2 {
  background-color: rgb(221, 170, 68);
}

.MuiTab-textColorPrimary.Mui-selected {
  background-color: rgb(221, 170, 68);
  color: #ffffff;
}

.fc-daygrid-dot-event {
    display: block;
}